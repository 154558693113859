<template>
  <div class="filter">
    <div class="filter-block container flex">
      <ul class="filter__items flex">
        <li class="filter__item">
          <b-form-input v-model="search" type="text" class="input__search" placeholder="Поиск" />
        </li>
        <li class="filter__item">
          <b-form-select       
            v-model="status"
            class="filter__item-select main__select input__search"
            :options="listStatus"
            text-field="ru"
            value-field="name"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select       
            v-model="city"
            class="filter__item-select main__select input__search"
            :options="listCities"
            value-field="id"
            text-field="name"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
      </ul>
      <slot name="logbook" class=""></slot>
    </div>
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selected: null,
      range: [],
      status: 'A',
      city: 'A',
      cityId: 0,
      date: null,
      dateShow: false,
      search: "",
      listCities: [
        { id: 'A', name: 'Город' },
      ],
      listStatus: [
        { name: 'A', ru: 'Статус' },
        {
          slug: "0",
          name: "free",
          ru: "Свободен",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "0-0",
          name: "moving",
          ru: "В пути",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "orders",
        }
      ]
    };
  },
  computed: {
    ...mapState(['citiesProducts'])
  },
  methods: {
    inputDataClean() {
      this.range = [];
      this.$store.commit("dateFilter", null);
      this.hideModal();
    },
    hideModal() {
      this.dateShow = false;
    },

    searchDrivers(){
      this.$store.dispatch('updateDriversList', {
          value : this.status
        })
    },

   async getDrivers(){
      await this.$store.dispatch("getDriversList");
    }
  },
  watch: {
    search: function(){
        if(this.search != ''){
          this.$store.dispatch('updateDriversList', {
          value : this.search
        })
      }else{
        this.$emit('getDriversList');
      }
    },
    status: async function(){
      if(this.city == 'A' && this.status != 'A'){
          this.$emit('getUserByStatus', {
            status : this.status
          })
        }else if(this.status == 'A'){
         await this.getDrivers()
        }else{
            await this.getDrivers();
            this.$emit('getUserByCityAndStatus', {
              cityId : this.city,
              status: this.status
            })
      }
    },
    city: async function() {
      if(this.status == 'A'){
          this.$emit('getUserByCityId', {
            cityId : this.city
          })
        }else{
        await this.getDrivers();
        this.$emit('getUserByCityAndStatus', {
            cityId : this.city,
            status: this.status
          })
      }

    }  
  },

    async created() {
  
        await this.$store.dispatch("getCitiesProducts");
        this.listCities = this.citiesProducts;
        this.listCities.unshift({ id: 'A', name: 'Город' });
  },
};
</script>

<style scoped>
.filter {
  padding: 10px 0 10px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
  position: relative;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}
.input__search {
  border: 1px solid #E0E9FA;
  border-radius: 8px;
  width: 300px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.add__calendar {
  position: static;
  background: #fff;
}
.filter__item:not(:last-child) {
  margin-right: 30px;
}
.filter__search {
  max-width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__search-address {
  font-size: 1rem;
}
.filter__item-select {
  /* background: #FFFFFF; */
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 250px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.select-map {
  width: 180px;
}
.main__select-icon {
  top: 50%;
}
.filter__item-btn {
  height: 100%;
  width: 250px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.filter__item-btn-icon {
  right: 3px;
}

.filter-btn {
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}

.filter-btn__icon {
  margin-right: 12px;
}

.filter__change {
  width: auto;
  padding-bottom: 15px;
  margin-top: 10px;
}

.filter__change-text {
  margin: 0px 12px;
}

.filter-label {
  background: var(--secondary-color);
}

.filter-label__defaul {
  background: #83b1f4;
}
</style>
